import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../assets/styles/responsive.css';
import '../assets/styles/style.css';

const About = () => {
  return (
    <div className="hero_area">
      <section className="about_section layout_padding">
        <div className="container">
          <div className="row align-items-center">
            {/* Text column - Will be on the left on large screens */}
            <div className="col-lg-6 col-md-12 order-lg-1 order-2">
              <div className="detail-box">
                <h2>Über uns</h2>
                <p>
                Willkommen bei Firma SIMNICA - Ihrem zuverlässigen Partner für effiziente Heizlösungen! Bei mir finden Sie die passende Lösung für Ihr Heizsystem. Firma Simnica besteht aus hochqualifizierten Fachleuten, die sich leidenschaftlich für ihre Arbeit engagieren. Ich lege großen Wert auf individuelle Beratung und maßgeschneiderte Lösungen, die genau auf Ihre Bedürfnisse abgestimmt sind. Durch den Einsatz modernster Technologien und energiesparender Systeme sorge ich dafür, dass Sie in Ihrem Zuhause oder Ihrem Unternehmen jederzeit ein angenehmes Klima genießen können. Kundenzufriedenheit hat bei mir oberste Priorität. Deshalb setze ich auf hochwertige Produkte, eine sorgfältige Ausführung und einen zuverlässigen Service. Bei Firma SIMNICA bin ich überzeugt, dass eine gut funktionierende Heizung mehr ist, als nur ein technisches Gerät - sie trägt wesentlich zu Ihrem Wohlbefinden bei. Lassen Sie uns gemeinsam für eine behagliche Wärme in Ihrem Zuhause sorgen!
                <br /><br />
                Kontaktieren Sie mich gerne für ein unverbindliches Beratungsgespräch. Ich freue mich darauf, Ihnen mit Rat und Tat zur Seite zu stehen!
                </p>
              </div>
            </div>
            
            {/* Image column - Will be on the right on large screens and below text on smaller screens */}
            <div className="col-lg-6 order-lg-2 order-1">
              <img 
                src="../../images/logoMain.jpg" 
                className="img-fluid static-image ml-lg-5" 
                alt="Company Logo"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
